export interface FileResponse {
  id: string;
  path: string;
  name: string;
  extension: string;
  size: number;
  mimetype: string;
  fileIsTranscoding?: boolean;
  fallbackPath?: string;
  thumbnailPath?: string;
}

export interface FileRequestResponse extends FileResponse {
  uploaderId: string;
}

export enum FileExtensionEnum {
  MASTER = '.m3u8',
}

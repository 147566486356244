import styled from 'styled-components';
import { SvgPath } from '../../../../../../helpers/constants/enums';

export const Container = styled.div`
  width: 100%;
  height: 90px;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ReferenceContainer = styled.div<{ $isActive: boolean }>`
  width: 100%;
  height: 90px;
  position: relative;
  z-index: 4;

  background: ${({ theme, $isActive }) =>
    $isActive
      ? `linear-gradient(180deg, ${theme.palette.main.marine} 0%, ${theme.palette.main.cyan} 100%)`
      : theme.palette.main.white};
  border-radius: 20px;
`;

export const ReferenceInsideBorder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 20px;
  padding: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;

  ::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    border-radius: 20px;
    border: 1px solid transparent;
    background: linear-gradient(
        87.33deg,
        rgba(0, 175, 185, 0.2) 21.62%,
        rgba(0, 175, 185, 0) 100%
      )
      border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const IconContainer = styled.div<{
  $isActive: boolean;
  $path: SvgPath;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 27px;
    height: 27px;
    path {
      stroke: ${({ $isActive, $path, theme }) =>
        $path === SvgPath.STROKE &&
        ($isActive ? theme.palette.main.white : theme.palette.main.marine)};
      fill: ${({ $isActive, $path, theme }) =>
        $path === SvgPath.FILL &&
        ($isActive ? theme.palette.main.white : theme.palette.main.marine)};
    }
  }
`;

export const Text = styled.div<{ $isActive: boolean }>`
  text-align: center;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(2)};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.palette.main.white : theme.palette.main.marine};

  @media only screen and (max-width: 350px) {
    font-size: ${({ theme }) => theme.typography.pxToRem(-3)};
    line-height: ${({ theme }) => theme.typography.pxToRem(1)};
  }

  @media only screen and (max-width: 330px) {
    font-size: ${({ theme }) => theme.typography.pxToRem(-4)};
    line-height: ${({ theme }) => theme.typography.pxToRem(0)};
  }
`;

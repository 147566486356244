import { ExperienceCardType } from '../../../uiModels/enums';
import { Experience } from '../../../uiModels/interfaces';

export const getExperienceCardType = (experience: Experience) => {
  switch (true) {
    case experience.isContest:
      return ExperienceCardType.Contest;
    case experience.isPermanent:
      return ExperienceCardType.Permanent;
    default:
      return ExperienceCardType.Regular;
  }
};

import { Filters } from 'components/dumb/layouts/FiltersModal/FiltersForm';
import { SearchFilters } from 'helpers/constants/enums';
import { useSearchParams } from 'react-router-dom';

const useExperiencesUrlParams = () => {
  const [filterParams, setFilterParams] = useSearchParams();

  const urlParams = {
    experienceType: filterParams.get('experienceType') ?? '',
    minPrice: filterParams.get('minPrice') ?? '',
    maxPrice: filterParams.get('maxPrice') ?? '',
    currency: filterParams.get('currency') ?? '',
    country: filterParams.get('country') ?? '',
    city: filterParams.get('city') ?? '',
    language: filterParams.get('language') ?? '',
    domain: filterParams.get('domain') ?? '',
    search: filterParams.get('search') ?? '',
    category: filterParams.get('category') ?? '',
  };

  function setFilterParamsToUrlFromObject(filtersData: Filters) {
    Object.keys(filtersData).forEach((key) => {
      const item = filtersData[key as keyof Filters];
      if (typeof item === 'object' && item.value) {
        setFilterParams((params) => {
          params.set(key, item.value);
          return params;
        });
      }
      if (typeof item !== 'object') {
        if (item) {
          setFilterParams((params) => {
            params.set(key, item);
            return params;
          });
        } else {
          setFilterParams((params) => {
            params.delete(key);
            return params;
          });
        }
      }
    });
  }

  function removeFilterParamsFromUrlFromObject(filtersData: Filters) {
    Object.keys(filtersData).forEach((key) => {
      const item = filtersData[key as keyof Filters];
      if (typeof item === 'object' && item.value) {
        setFilterParams((params) => {
          params.delete(key);
          return params;
        });
      } else if (typeof item !== 'object' && item) {
        setFilterParams((params) => {
          params.delete(key);
          return params;
        });
      }
    });
  }

  function removeFilterParamsByKeysFromUrl(keysToRemove: string[]) {
    keysToRemove.forEach((key) => {
      setFilterParams((params) => {
        params.delete(key);
        return params;
      });
    });
  }

  function setUrlSearch(search: string) {
    setFilterParams((params) => {
      params.set('search', search);
      return params;
    });
  }

  function setUrlCategory(category: SearchFilters) {
    setFilterParams((params) => {
      params.set('category', category);
      return params;
    });
  }

  function removeUrlSearch() {
    setFilterParams((params) => {
      params.delete('search');
      params.delete('category');
      return params;
    });
  }

  return {
    urlParams,
    setFilterParams,
    setUrlSearch,
    setUrlCategory,
    setFilterParamsToUrlFromObject,
    removeFilterParamsFromUrlFromObject,
    removeFilterParamsByKeysFromUrl,
    removeUrlSearch,
  };
};

export default useExperiencesUrlParams;

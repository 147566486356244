import { lazy, Suspense } from 'react';
import LoadingSpinner from 'components/dumb/atomics/LoadingSpinner';
import * as SC from './styles';

const ExminderIcon = lazy(
  () => import('assets/icons/introductionIcons/ExminderIcon')
);

function LazyExminderIcon() {
  return (
    <Suspense
      fallback={
        <SC.CenteredContainer>
          <LoadingSpinner />
        </SC.CenteredContainer>
      }
    >
      <ExminderIcon />
    </Suspense>
  );
}

export default LazyExminderIcon;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFilters } from 'helpers/constants/enums';
import SearchFiltersTab, { Tab } from './SearchFiltersTab';
import * as SC from './styles';

interface Props {
  searchFilter: string;
  onSelectSearchFilter: (value: string) => void;
}

export default function SearchFiltersBar({
  searchFilter,
  onSelectSearchFilter,
}: Props) {
  const { t } = useTranslation();
  const [selectedSearchFilter, setSelectedSearchFilter] =
    useState(searchFilter);

  const handleSelectSearchFilter = (value: string) => {
    setSelectedSearchFilter(value);
    onSelectSearchFilter(value);
  };

  const tabs: Tab[] = [
    {
      value: SearchFilters.EXPERIENCES,
      label: t('experience.filters.experiences'),
    },
    {
      value: SearchFilters.ACCOUNTS,
      label: t('experience.filters.accounts'),
    },
    // {
    //   value: SearchFilters.AUCTIONS,
    //   label: t('experience.filters.auctions'),
    // },
  ];

  const mappedTabs = tabs.map((tab) => (
    <SearchFiltersTab
      key={tab.value}
      tab={tab}
      isSelected={selectedSearchFilter === tab.value}
      onSelectTab={handleSelectSearchFilter}
    />
  ));

  return <SC.Container>{mappedTabs}</SC.Container>;
}

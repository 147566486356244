import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { handleLanguageChange } from './helpers';

const detectionOptions = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'en',
      ns: ['translations'],
      defaultNS: 'translations',
      keySeparator: '.',
      detection: detectionOptions,
      load: 'languageOnly',
      debug: true,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
    },
    async () => {
      const currentLanguage = i18n.language.split('-')[0] || 'en';

      try {
        handleLanguageChange(currentLanguage);
      } catch (error) {
        console.error('Error loading translations:', error);
      }
    }
  );

export default i18n;

import { useEffect, useRef, useState, MouseEvent } from 'react';
import { useTheme } from 'styled-components';
import { t } from 'i18next';
import LazyExmindsLogo from 'assets/lazyLoad/LazyLoadExmindsLogo';
import * as SC from './styles';

interface ConfirmActionModalProps {
  title?: string;
  description?: string[];
  list?: string[];
  footer?: string;
  cancelText?: string;
  confirmationText?: string;
  allowOnOutsideClickClose?: boolean;
  buttonsWrapWidth?: number;
  isLoading?: boolean;
  isOpen: boolean;
  onClose: CallbackFunction;
  onConfirm: CallbackFunction;
}

export default function ConfirmActionModal({
  title = t(`general.confirmAction`),
  description = [],
  list = [],
  footer = '',
  cancelText = t(`general.cancel`),
  confirmationText = t(`general.confirm`),
  allowOnOutsideClickClose = true,
  buttonsWrapWidth = 480,
  isLoading = false,
  isOpen,
  onClose,
  onConfirm,
}: ConfirmActionModalProps) {
  const theme = useTheme();
  const backdropRef = useRef<HTMLDivElement | null>(null);
  const [isBackdropMouseDown, setIsBackdropMouseDown] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (typeof window !== 'undefined' && window.document) {
        document.body.style.overflow = 'hidden';
      }
    } else document.body.style.overflow = '';
  }, [isOpen]);

  const handleClose = () => {
    onClose();
    document.body.style.overflow = '';
  };

  const handleConfirm = () => {
    onConfirm();
    document.body.style.overflow = '';
  };

  const onBackdropMouseDown = (event: MouseEvent) => {
    setIsBackdropMouseDown(backdropRef.current === event.target);
  };

  const onBackdropMouseUp = (event: MouseEvent) => {
    const isBackdropMouseUp = backdropRef.current === event.target;
    if (
      isBackdropMouseDown &&
      isBackdropMouseUp &&
      allowOnOutsideClickClose &&
      !isLoading
    ) {
      handleClose();
    }
  };

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <SC.Backdrop
      ref={backdropRef}
      isOpen={isOpen}
      onMouseDown={onBackdropMouseDown}
      onMouseUp={onBackdropMouseUp}
    >
      <SC.Container isOpen={isOpen} onClick={stopPropagation}>
        <SC.IconContainer>
          <LazyExmindsLogo />
        </SC.IconContainer>
        <SC.Title>{title}</SC.Title>
        {description?.length > 0 &&
          description.map((item: string) => (
            <SC.Text key={item}>{item}</SC.Text>
          ))}
        {list?.length > 0 && (
          <SC.List>
            {list.map((item: string) => (
              <SC.Item key={item}>{item}</SC.Item>
            ))}
          </SC.List>
        )}
        {footer?.length > 0 && <SC.Text>{footer}</SC.Text>}
        <SC.ButtonContainer buttonsWrapWidth={buttonsWrapWidth}>
          <SC.StyledButton
            color={theme.palette.main.lightGray}
            onClick={handleClose}
          >
            {cancelText}
          </SC.StyledButton>
          <SC.StyledButton
            color={theme.palette.button.main.turquoiseYellow}
            onClick={handleConfirm}
          >
            {confirmationText}
          </SC.StyledButton>
        </SC.ButtonContainer>
      </SC.Container>
    </SC.Backdrop>
  );
}

import { useTranslation } from 'react-i18next';
import {
  CalendarIcon,
  CommunityGuidelinesIcon,
  FAQIcon,
  FavoritesIcon,
  FeedbackIcon,
  HowItWorksIcon,
  MoneyIcon,
  MyProfileIcon,
  PrivacyIcon,
  ReferralIcon,
  SettingsIcon,
  TermsIcon,
} from 'assets/icons/SideBarMenuIcons/SideBarIcons';
import * as SC from './styles';
import Avatar from '../../../../atomics/Avatar';
import SideBarCard from '../../SideBarCard';
import { SideBarSteps } from '../../../../../../helpers/constants/enums';
import LogoutIcon from '../../../../../../assets/icons/buttonIcons/LogoutIcon';
import FileIcon from '../../../../../../assets/icons/invoices/FileIcon';

interface User {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  role?: {
    name: string;
  };
}

interface SideBarMainPageStepProps {
  user: User;
  avatar: string;
  isProfileImageCompressing: boolean;
  exminderView: boolean;
  shouldAnimate: boolean;
  onHandleChangeRole: CallbackFunction;
  onMyProfileClick: CallbackFunction;
  onAccountSettingsClick: CallbackFunction;
  onMyCalendarClick: CallbackFunction;
  onFavoritesClick: CallbackFunction;
  onStatisticsClick: CallbackFunction;
  onMoneyWithExmindsClick: CallbackFunction;
  onReferralDashboardClick: CallbackFunction;
  onInvoicesClick: CallbackFunction;
  onHowItWorksClick: CallbackFunction;
  onCommunityGuidelinesClick: CallbackFunction;
  onFaqClick: CallbackFunction;
  onFeedbackClick: CallbackFunction;
  onTermsAndConditionsClick: CallbackFunction;
  onPrivacyPolicyClick: CallbackFunction;
  onLogout: CallbackFunction;
  currentStep: number;
  isAuthenticated: boolean;
  isOnboardingSkipped: boolean;
  hasReferralAccount: boolean;
}

export default function SideBarMainPageStep({
  user,
  avatar,
  isProfileImageCompressing,
  exminderView,
  shouldAnimate,
  onHandleChangeRole,
  onMyProfileClick,
  onAccountSettingsClick,
  onMyCalendarClick,
  onFavoritesClick,
  onStatisticsClick,
  onMoneyWithExmindsClick,
  onReferralDashboardClick,
  onInvoicesClick,
  onHowItWorksClick,
  onCommunityGuidelinesClick,
  onFaqClick,
  onFeedbackClick,
  onTermsAndConditionsClick,
  onPrivacyPolicyClick,
  onLogout,
  currentStep,
  isAuthenticated,
  isOnboardingSkipped,
  hasReferralAccount,
}: SideBarMainPageStepProps) {
  const { t } = useTranslation();

  const isGuestProfile = !isAuthenticated || isOnboardingSkipped;

  const generateProfileText = (): string => {
    if (!isAuthenticated) {
      return t('auth.logIn');
    }
    if (isOnboardingSkipped) {
      return t('sidebarMenu.continueOnboarding');
    }
    return t(`sidebarMenu.myProfile`);
  };

  const generateExtendedBoldText = (): string => {
    if (isGuestProfile) {
      return '';
    }
    return exminderView ? t('general.exminder') : t('general.explorer');
  };

  if (currentStep !== SideBarSteps.MainPageStep) {
    return null;
  }

  return (
    <SC.Container>
      <SC.MainContent>
        <SC.MenuItemsContent>
          <SC.UserDetailsContainer>
            <Avatar
              width={125}
              height={125}
              image={avatar}
              isImageCompressing={isProfileImageCompressing}
              switchIcon={!isGuestProfile}
              isExminderView={exminderView && !isGuestProfile}
              onHandleChangeRole={onHandleChangeRole}
              animate={shouldAnimate}
              isVerified={exminderView && !isGuestProfile}
            />
            <SC.NameContainer>
              {isGuestProfile
                ? t(`sidebarMenu.guestProfile`)
                : `${user?.firstName?.toUpperCase()} ${user?.lastName?.toUpperCase()}`}
            </SC.NameContainer>
            {isGuestProfile && (
              <SC.EmailContainer>{user.email}</SC.EmailContainer>
            )}
          </SC.UserDetailsContainer>
          <SC.ProfileAccountSection>
            <SideBarCard
              icon={<MyProfileIcon />}
              text={generateProfileText()}
              onClick={onMyProfileClick}
              extendedBoldText={generateExtendedBoldText()}
            />
            <SideBarCard
              icon={<SettingsIcon />}
              text={t(`sidebarMenu.accountSettings`)}
              onClick={onAccountSettingsClick}
            />
          </SC.ProfileAccountSection>
          <SC.HorizontalSeparator />
          <SC.InformationSection>
            <SideBarCard
              icon={<CalendarIcon />}
              text={t(`sidebarMenu.myCalendar`)}
              onClick={onMyCalendarClick}
              disabled={isGuestProfile}
            />
            <SideBarCard
              icon={<FavoritesIcon />}
              text={t(`sidebarMenu.favorites`)}
              onClick={onFavoritesClick}
              disabled={isGuestProfile}
            />
            {isAuthenticated && exminderView && (
              <>
                {/* <SideBarCard
                  icon={<StatisticsIcon />}
                  text={t(`sidebarMenu.statistics`)}
                  onClick={onStatisticsClick}
                  disabled
                /> */}
                <SideBarCard
                  icon={<MoneyIcon />}
                  text={t(`sidebarMenu.moneyWithExminds`)}
                  onClick={onMoneyWithExmindsClick}
                />
              </>
            )}
            <SideBarCard
              icon={<FileIcon />}
              text={t(`sidebarMenu.invoices`)}
              onClick={onInvoicesClick}
              disabled={isGuestProfile}
            />
            {hasReferralAccount && (
              <SideBarCard
                icon={<ReferralIcon />}
                text={t(`sidebarMenu.exmindsPartners`)}
                onClick={onReferralDashboardClick}
              />
            )}
          </SC.InformationSection>
          <SC.HorizontalSeparator />
          <SC.InformationSection>
            <SideBarCard
              icon={<HowItWorksIcon />}
              text={t(`sidebarMenu.howItWorks`)}
              onClick={onHowItWorksClick}
            />
            <SideBarCard
              icon={<CommunityGuidelinesIcon />}
              text={t(`sidebarMenu.communityGuidelines`)}
              onClick={onCommunityGuidelinesClick}
            />
            <SideBarCard
              icon={<FAQIcon />}
              text={t(`sidebarMenu.faq`)}
              onClick={onFaqClick}
            />
            <SideBarCard
              icon={<TermsIcon />}
              text={t(`sidebarMenu.termsAndConditions`)}
              onClick={onTermsAndConditionsClick}
            />
            <SideBarCard
              icon={<PrivacyIcon />}
              text={t(`sidebarMenu.privacyPolicy`)}
              onClick={onPrivacyPolicyClick}
            />
            <SideBarCard
              icon={<FeedbackIcon />}
              iconColor="#26C3A7"
              text={t(`sidebarMenu.feedback`)}
              onClick={onFeedbackClick}
              disabled={isGuestProfile}
            />
          </SC.InformationSection>
          <SC.HorizontalSeparator />
        </SC.MenuItemsContent>
      </SC.MainContent>
      <SC.LogoutContainer>
        <SideBarCard
          icon={<LogoutIcon />}
          text={t(`auth.logOut`)}
          onClick={onLogout}
          disabled={!isAuthenticated}
        />
      </SC.LogoutContainer>
    </SC.Container>
  );
}

import { Route } from './types';
import PATH from './paths';
import Dashboard from '../components/smart/pages/Dashboard';

let routes: Route[] = [];

const routeFiles = require.context(
  './',
  true,
  /^(?!.\/index|.\/paths|.\/patterns|.\/types|routes).*\.ts$/
);

routeFiles.keys().forEach((routeFile: string) => {
  routes = [...routes, ...routeFiles(routeFile).default];
});

const dashboard: Route = {
  path: PATH.INDEX,
  component: Dashboard,
  isProtected: true,
  hasMenuBar: true,
};

export default [...routes, dashboard];

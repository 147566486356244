import { lazy, Suspense } from 'react';
import LoadingSpinner from 'components/dumb/atomics/LoadingSpinner';
import * as SC from './styles';

const ExmindsLogo = lazy(() => import('assets/logo/ExmindsLogo'));

function LazyExmindsLogo() {
  return (
    <Suspense
      fallback={
        <SC.CenteredContainer>
          <LoadingSpinner />
        </SC.CenteredContainer>
      }
    >
      <ExmindsLogo />
    </Suspense>
  );
}

export default LazyExmindsLogo;

import * as SC from './styles';

export interface Tab {
  value: string;
  label: string;
}

interface Props {
  tab: Tab;
  isSelected: boolean;
  onSelectTab: (value: string) => void;
}

export default function SearchFiltersTab({
  tab,
  isSelected,
  onSelectTab,
}: Props) {
  const handleSelectSearchFilter = () => {
    onSelectTab(tab.value);
  };

  return (
    <SC.Container onClick={handleSelectSearchFilter}>
      <SC.Content isSelected={isSelected}>{tab.label}</SC.Content>
      <SC.SelectionLineContainer>
        {isSelected && <SC.SelectionLine />}
      </SC.SelectionLineContainer>
    </SC.Container>
  );
}

import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { fileRequest } from 'api/FileRequest';
import {
  ApiResponseGetPackages,
  ApiResponsePackage,
  packageRequest,
} from 'api/PackageRequest';

function useGetExperiences(
  queryKey: string,
  queryOptions: {
    limit: number;
    domain?: string;
    type?: string;
    minPrice?: number;
    maxPrice?: number;
    currency?: string;
    countryId?: string;
    cityId?: string;
    languageId?: string;
    searchText?: string;
  },
  enabled?: boolean
) {
  const QUERY_KEY = [queryKey, queryOptions];
  const {
    limit,
    domain,
    type,
    minPrice,
    maxPrice,
    currency,
    countryId,
    cityId,
    languageId,
    searchText,
  } = queryOptions;
  const fetchExperiences = async ({ pageParam = 0 }) =>
    packageRequest.getPackages({
      limit,
      offset: pageParam,
      domain,
      type,
      minPrice,
      maxPrice,
      currency,
      countryId,
      cityId,
      languageId,
      searchText,
    });

  const selectData = (data: InfiniteData<ApiResponseGetPackages>) => ({
    pages: data.pages
      .flatMap((res) => res.results)
      .map((element: ApiResponsePackage) => ({
        ...element,
        presentationPhoto: fileRequest.getPublicUrl(element.presentationPhoto),
        presentationVideo: fileRequest.getPublicUrl(element.presentationVideo),
        videoThumbnail: element.presentationVideo?.thumbnailPath
          ? fileRequest.getPublicUrl({
              ...element.presentationVideo,
              path: element.presentationVideo.thumbnailPath,
            })
          : undefined,
        city: element.city.name,
        language: element.language.name,
        domain: element.domain.name,
        consumersCount: element.consumers.length,
        isPermanent: element.isPermanent,
        exminder: {
          id: element.contentCreator.id,
          firstName: element.contentCreator.firstName,
          lastName: element.contentCreator.lastName,
          profilePicture: fileRequest.getPublicUrl(
            element.contentCreator.profilePicture
          ),
          workExperience: element.contentCreator.cv?.workExperiences.map(
            (workExperience) => ({
              companyName: workExperience.company,
              companyRole: workExperience.title,
              startDate: workExperience.startDate,
            })
          ),
        },
      })),
    pageParams: data.pageParams,
  });

  return useInfiniteQuery({
    queryKey: QUERY_KEY,
    queryFn: fetchExperiences,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (
        !lastPage ||
        !lastPage.results ||
        lastPage.results.length === 0 ||
        lastPage.total <= limit ||
        lastPage.total <= allPages.length * limit
      ) {
        return undefined;
      }
      return lastPageParam + limit;
    },
    select: selectData,
    enabled,
  });
}
export default useGetExperiences;

import { FieldConfig } from 'formik';
import { ReactElement } from 'react';
import * as SC from './styles';

interface Props extends FieldConfig {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  autoComplete?: 'on' | 'off';
  type?: 'text' | 'date' | 'number';
  icon?: ReactElement;

  [rest: string]: unknown;
}

export default function FieldInput({
  name,
  placeholder = '',
  disabled = false,
  autoComplete = 'on',
  type = 'text',
  icon,
  ...rest
}: Props) {
  return (
    <SC.Container>
      <SC.ReferenceContainer>
        <SC.IconContainer>{icon}</SC.IconContainer>
        <SC.ReferenceInsideBorder>
          <SC.StyledInput
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autoComplete}
            $hasIcon={Boolean(icon)}
            {...rest}
          />
        </SC.ReferenceInsideBorder>
      </SC.ReferenceContainer>
    </SC.Container>
  );
}

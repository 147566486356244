import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as SC from './styles';
import avatarPlaceholder from '../../../../assets/images/placeholders/ProfilePicEmpty.webp';
import experiencePhotoPlaceholder from '../../../../assets/images/placeholders/ExperiencePhotoAvatarPlaceholder.webp';
import PlusIcon from '../../../../assets/icons/PlusIcon';
import acceptedImageMimeTypes from '../../../../helpers/constants/acceptedImageMimeTypes';
import ExpandIcon from '../../../../assets/icons/ExpandIcon';
import { Placement } from '../../../../uiModels/enums/floatingUI';
import VerifiedBadge from '../VerifiedBadge';

interface Props {
  image: string;
  isImageCompressing?: boolean;
  width?: number;
  height?: number;
  isEditMode?: boolean;
  switchIcon?: boolean;
  isExminderView?: boolean;
  onHandleChangeRole?: () => void;
  onImageUpload?: (file: File) => void;
  animate?: boolean;
  isExperiencePhoto?: boolean;
  isExpandable?: boolean;
  isVerified?: boolean;
}

export default function Avatar({
  image,
  isImageCompressing = false,
  width = 143,
  height = 143,
  switchIcon = false,
  isEditMode = false,
  isExminderView,
  onHandleChangeRole,
  onImageUpload,
  animate = false,
  isExperiencePhoto = false,
  isExpandable = false,
  isVerified = false,
}: Props) {
  const [uploadedImage, setUploadedImage] = useState(
    image || avatarPlaceholder
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const imageUploader = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setUploadedImage(image);
  }, [image]);

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (onImageUpload) onImageUpload(file);
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleExpand = () => {
    if (isExpandable) setIsExpanded((prev) => !prev);
  };

  const getImageSrc = () =>
    isExperiencePhoto ? experiencePhotoPlaceholder : uploadedImage;

  return (
    <>
      <SC.Container width={width} height={height}>
        <SC.Background exminderView={isExminderView}>
          {isEditMode && (
            <SC.Input
              type="file"
              accept={acceptedImageMimeTypes.join(',')}
              onChange={handleImageUpload}
              ref={imageUploader}
            />
          )}
          {isImageCompressing ? (
            <SC.LoadingBackground width={width} height={height}>
              <SC.LoadingSpinner />
            </SC.LoadingBackground>
          ) : (
            <SC.AvatarImageWrapper
              width={width}
              height={height}
              $isExpandable={isExpandable}
              onClick={toggleExpand}
            >
              {isExpandable && (
                <SC.ExpandIconContainer>
                  <ExpandIcon />
                </SC.ExpandIconContainer>
              )}
              <SC.AvatarImage
                src={getImageSrc()}
                onError={(e: SyntheticEvent<HTMLImageElement>) => {
                  e.currentTarget.src = isExperiencePhoto
                    ? experiencePhotoPlaceholder
                    : avatarPlaceholder;
                }}
                width={width}
                height={height}
                $isExpandable={isExpandable}
              />
            </SC.AvatarImageWrapper>
          )}
        </SC.Background>
        {switchIcon && (
          <SC.SwitchButton
            $isExminderView={isExminderView}
            onClick={onHandleChangeRole}
          >
            <SC.StyledSwitchAccountIcon $animate={animate} />
          </SC.SwitchButton>
        )}
        {isVerified && (
          <SC.VerifiedBadgeContainer>
            <VerifiedBadge placement={Placement.BOTTOM} />
          </SC.VerifiedBadgeContainer>
        )}
        {isEditMode && (
          <SC.EditButton onClick={() => imageUploader.current?.click()}>
            <PlusIcon />
          </SC.EditButton>
        )}
      </SC.Container>
      {isExpandable && isExpanded && (
        <SC.Overlay onClick={toggleExpand}>
          <SC.ExpandedAvatarImage
            src={getImageSrc()}
            onError={(e: SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = getImageSrc();
            }}
          />
        </SC.Overlay>
      )}
    </>
  );
}

import eventEmitter from 'eventEmitter';
import { useEffect, useState } from 'react';

const useEventListen = (event: string) => {
  const [isEventTriggered, setIsEventTriggered] = useState(false);

  const handleResetEvent = () => {
    setIsEventTriggered(false);
  };

  useEffect(() => {
    const handleEvent = () => {
      setIsEventTriggered(true);
    };

    eventEmitter.on(event, handleEvent);

    return () => {
      eventEmitter.off(event, handleEvent);
    };
  }, [event]);

  return { isEventTriggered, handleResetEvent };
};

export default useEventListen;

import { ReactNode, useRef, useState } from 'react';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { Placement } from 'uiModels/enums/floatingUI';
import * as SC from './styles';
import theme from '../../../../styles/theme/classic';

interface Props {
  children: ReactNode;
  content: string;
  placement?: Placement;
  fill?: string;
  color?: string;
  width?: string;
  minWidth?: string;
}

export default function Tooltip({
  children,
  content,
  placement = Placement.TOP,
  fill = theme.palette.main.white,
  color = theme.palette.main.darkMarine,
  width = '400px',
  minWidth,
}: Props) {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const ARROW_HEIGHT = 7;
  const GAP = 2;

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(ARROW_HEIGHT + GAP),
      flip(),
      shift({ padding: 20 }),
      arrow({ element: arrowRef }),
    ],
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <SC.Container>
      <SC.ReferenceElement ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </SC.ReferenceElement>
      <FloatingPortal>
        {isOpen && (
          <SC.TooltipContent
            ref={refs.setFloating}
            style={floatingStyles}
            fill={fill}
            color={color}
            width={width}
            minWidth={minWidth}
            {...getFloatingProps()}
          >
            {content}
            <FloatingArrow ref={arrowRef} context={context} fill={fill} />
          </SC.TooltipContent>
        )}
      </FloatingPortal>
    </SC.Container>
  );
}

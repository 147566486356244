import AdventuresIcon from 'assets/icons/domains/AdventuresIcon';
import AllDomainsIcon from 'assets/icons/domains/AllDomainsIcon';
import BusinessIcon from 'assets/icons/domains/BusinessIcon';
import CreativityIcon from 'assets/icons/domains/CreativityIcon';
import ExpertsIcon from 'assets/icons/domains/ExpertsIcon';
import ExtrasIcon from 'assets/icons/domains/ExtrasIcon';
import HealthcareIcon from 'assets/icons/domains/HealthcareIcon';
import LifestyleIcon from 'assets/icons/domains/LifestyleIcon';
import MindfulnessIcon from 'assets/icons/domains/MindfulnessIcon';
import ParentingIcon from 'assets/icons/domains/ParentingIcon';
import SmallTalkIcon from 'assets/icons/domains/SmallTalkIcon';
import TechnologyIcon from 'assets/icons/domains/TechnologyIcon';
import TravelingIcon from 'assets/icons/domains/TravelingIcon';
import i18n from 'i18next';

export const domainTabs = () => [
  {
    icon: <AllDomainsIcon />,
    label: i18n.t(`domains.all`),
    value: '',
    isChecked: false,
  },
  {
    icon: <BusinessIcon />,
    label: i18n.t(`domains.business`),
    value: 'business',
    isChecked: false,
  },
  {
    icon: <LifestyleIcon />,
    label: i18n.t(`domains.lifestyle`),
    value: 'lifestyle',
    isChecked: false,
  },
  {
    icon: <ExpertsIcon />,
    label: i18n.t(`domains.experts`),
    value: 'experts',
    isChecked: false,
  },
  {
    icon: <TechnologyIcon />,
    label: i18n.t(`domains.technology`),
    value: 'technology',
    isChecked: false,
  },
  {
    icon: <CreativityIcon />,
    label: i18n.t(`domains.creativity`),
    value: 'creativity',
    isChecked: false,
  },
  {
    icon: <TravelingIcon />,
    label: i18n.t(`domains.traveling`),
    value: 'traveling',
    isChecked: false,
  },
  {
    icon: <AdventuresIcon />,
    label: i18n.t(`domains.adventures`),
    value: 'adventures',
    isChecked: false,
  },
  {
    icon: <ParentingIcon />,
    label: i18n.t(`domains.parenting`),
    value: 'parenting',
    isChecked: false,
  },
  {
    icon: <HealthcareIcon />,
    label: i18n.t(`domains.healthcare`),
    value: 'healthcare',
    isChecked: false,
  },
  {
    icon: <MindfulnessIcon />,
    label: i18n.t(`domains.mindfulness`),
    value: 'mindfulness',
    isChecked: false,
  },
  {
    icon: <SmallTalkIcon />,
    label: i18n.t(`domains.small_talk`),
    value: 'small_talk',
    isChecked: false,
  },
  {
    icon: <ExtrasIcon />,
    label: i18n.t(`domains.extras`),
    value: 'extras',
    isChecked: false,
  },
];

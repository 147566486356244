import AbstractRequest from './AbstractRequest';
import { ApiMethod } from './constants';
import {
  ApiRequestBodyRegisterReferralLinkClick,
  ApiRequestBodySetConsumerProfileData,
  ApiRequestBodySetUserData,
  ApiRequestBodySetUserDomains,
  ApiRequestBodySetUserHobbies,
  ApiRequestBodySetUserProfilePicture,
  ApiRequestBodySetUserRole,
  ApiResponseBodyGetReferralAccount,
  ApiResponseBodyReferralAccountHistory,
  ApiResponseBodyReferralAccountReferredUsers,
  ApiResponseContentCreatorView,
  ApiResponseExplorer,
  ApiSearchUsersResponse,
} from './types/user';

export class UserRequest extends AbstractRequest {
  get apiResource(): string {
    return 'user';
  }

  get dynamicIds(): Record<string, string> {
    return {
      id: '{id}',
      slug: '{slug}',
    };
  }

  get routes(): Record<string, string> {
    return {
      SET_USER_ROLE: `${this.apiRoute}/onboarding/set-user-role`,
      SET_USER_DATA: `${this.apiRoute}/onboarding/set-user-data`,
      SET_USER_DOMAINS: `${this.apiRoute}/onboarding/set-user-domains`,
      SET_USER_HOBBIES: `${this.apiRoute}/onboarding/set-user-hobbies`,
      SET_USER_PROFILE_PICTURE: `${this.apiRoute}/onboarding/set-user-profile-picture`,
      SKIP_ONBOARDING_PROCESS: `${this.apiRoute}/onboarding/skip`,
      GET_EXPLORER_PROFILE: `${this.apiRoute}/${this.dynamicIds.id}/consumer-view`,
      GET_CONTENT_CREATOR_PROFILE: `${this.apiRoute}/${this.dynamicIds.id}/content-creator-view`,
      SET_USER_FIRST_NAME: `${this.apiRoute}/set-user-first-name`,
      SET_USER_LAST_NAME: `${this.apiRoute}/set-user-last-name`,
      SET_USER_PASSWORD: `${this.apiRoute}/set-user-password`,
      SET_USER_EMAIL: `${this.apiRoute}/set-user-email`,
      CONFIRM_NEW_EMAIL: `${this.apiRoute}/confirm-new-email`,
      SET_PERSONAL_DETAILS: `${this.apiRoute}/set-user-personal-details`,
      UPDATE_USER_PROFILE_PICTURE: `${this.apiRoute}/set-user-profile-picture`,
      EDIT_USER_DOMAINS: `${this.apiRoute}/set-user-domains`,
      EDIT_USER_HOBBIES: `${this.apiRoute}/set-user-hobbies`,
      SET_CONSUMER_DATA: `${this.apiRoute}/set-consumer-profile-data`,
      GET_USER_SEARCHED: `${this.apiRoute}/search`,
      UPGRATE_TO_CONTENT_CREATOR: `${this.apiRoute}/upgrade-to-content-creator`,
      DELETE_MY_ACCOUNT: `${this.apiRoute}/delete-my-account`,
      SET_FINANCIAL_SETTINGS: `${this.apiRoute}/set-user-financial-settings`,
      GET_REFERRAL_ACCOUNT: `${this.apiRoute}/referral-account`,
      GET_REFERRAL_ACCOUNT_HISTORY: `${this.apiRoute}/referral-account-history`,
      GET_REFERRAL_ACCOUNT_REFERRED_USERS: `${this.apiRoute}/referral-account-referred-users`,
      CREATE_REFERRAL_ACCOUNT: `${this.apiRoute}/create-referral-account`,
      REGISTER_REFERRAL_LINK_CLICK: `${this.apiRoute}/register-referral-link-click`,
      GET_USER_ID_BY_SLUG: `${this.apiRoute}/get-id-by-slug/${this.dynamicIds.slug}`,
    };
  }

  async getUserIdBySlug(slug: string): Promise<{ id: string }> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_USER_ID_BY_SLUG!.replace(this.dynamicIds.slug!, slug)
    );

    return result.data;
  }

  async setUserRole(data: ApiRequestBodySetUserRole): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_USER_ROLE as string,
      data
    );

    return result.data;
  }

  async setUserData(data: ApiRequestBodySetUserData): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_USER_DATA as string,
      data
    );

    return result.data;
  }

  async setConsumerProfileData(
    data: ApiRequestBodySetConsumerProfileData
  ): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_CONSUMER_DATA as string,
      data
    );

    return result.data;
  }

  async setUserDomains(data: ApiRequestBodySetUserDomains): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_USER_DOMAINS as string,
      data
    );

    return result.data;
  }

  async editUserDomains(data: ApiRequestBodySetUserDomains): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.EDIT_USER_DOMAINS as string,
      data
    );

    return result.data;
  }

  async setUserHobbies(data: ApiRequestBodySetUserHobbies): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_USER_HOBBIES as string,
      data
    );

    return result.data;
  }

  async editUserHobbies(data: ApiRequestBodySetUserHobbies): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.EDIT_USER_HOBBIES as string,
      data
    );

    return result.data;
  }

  async setUserProfilePicture(
    data: ApiRequestBodySetUserProfilePicture
  ): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_USER_PROFILE_PICTURE as string,
      data
    );

    return result.data;
  }

  async updateUserProfilePicture(
    data: ApiRequestBodySetUserProfilePicture
  ): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.UPDATE_USER_PROFILE_PICTURE as string,
      data
    );

    return result.data;
  }

  async setUserFirstName(data: { firstName: string }): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_USER_FIRST_NAME as string,
      data
    );

    return result.data;
  }

  async setUserLastName(data: { lastName: string }): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_USER_LAST_NAME as string,
      data
    );

    return result.data;
  }

  async setUserPassword(data: {
    currentPassword: string;
    newPassword: string;
  }): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_USER_PASSWORD as string,
      data
    );

    return result.data;
  }

  async setUserEmail(data: { email: string }): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_USER_EMAIL as string,
      data
    );

    return result.data;
  }

  async confirmNewEmail(data: { code: number }): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.CONFIRM_NEW_EMAIL as string,
      data
    );

    return result.data;
  }

  async setUserPersonalDetails(data: {
    birthdate: string;
    nativeLanguageId: string;
    otherLanguageIds: string[];
    cityId: string;
  }): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_PERSONAL_DETAILS as string,
      data
    );

    return result.data;
  }

  async getExplorerProfile(id: string): Promise<ApiResponseExplorer> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_EXPLORER_PROFILE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async getContentCreatorProfile(
    id: string
  ): Promise<ApiResponseContentCreatorView> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_CONTENT_CREATOR_PROFILE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async getUsersSearched(queryOptions: {
    limit: number;
    offset: number;
    searchText?: string;
  }): Promise<ApiSearchUsersResponse> {
    const queryParams = [
      { key: 'limit', value: `${queryOptions.limit}` },
      { key: 'offset', value: `${queryOptions.offset}` },
    ];

    if (queryOptions.searchText) {
      queryParams.push({ key: 'searchText', value: queryOptions.searchText });
    }

    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_USER_SEARCHED + this.buildQueryParams(queryParams)
    );
    return result.data;
  }

  async skipOnboardingProcess(): Promise<boolean> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SKIP_ONBOARDING_PROCESS as string
    );

    return result.data;
  }

  async upgradeToContentCreator(): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.UPGRATE_TO_CONTENT_CREATOR as string
    );

    return result.data;
  }

  async deleteMyAccount(): Promise<string> {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE_MY_ACCOUNT as string
    );

    return result.data;
  }

  async setUserFinancialSettings(data: {
    dontSendExminderCompanyToExplorerInvoiceEmail: boolean;
  }): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_FINANCIAL_SETTINGS as string,
      data
    );

    return result.data;
  }

  async getReferralAccount(): Promise<ApiResponseBodyGetReferralAccount> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_REFERRAL_ACCOUNT as string
    );

    return result.data;
  }

  async getReferralAccountHistory(
    limit: number,
    offset: number
  ): Promise<ApiResponseBodyReferralAccountHistory> {
    const queryParams = [
      { key: 'limit', value: `${limit}` },
      { key: 'offset', value: `${offset}` },
    ];
    const result = await this.request(
      ApiMethod.GET,
      (this.routes.GET_REFERRAL_ACCOUNT_HISTORY as string) +
        this.buildQueryParams(queryParams)
    );

    return result.data;
  }

  async getReferralAccountReferredUsers(
    limit: number,
    offset: number
  ): Promise<ApiResponseBodyReferralAccountReferredUsers> {
    const queryParams = [
      { key: 'limit', value: `${limit}` },
      { key: 'offset', value: `${offset}` },
    ];
    const result = await this.request(
      ApiMethod.GET,
      (this.routes.GET_REFERRAL_ACCOUNT_REFERRED_USERS as string) +
        this.buildQueryParams(queryParams)
    );

    return result.data;
  }

  async registerReferralLinkClick(
    data: ApiRequestBodyRegisterReferralLinkClick
  ): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.REGISTER_REFERRAL_LINK_CLICK as string,
      data,
      { withCredentials: true }
    );

    return result.data;
  }
}

export const userRequest = new UserRequest();

export const COMPRESS_IMAGE_EXTENSION = 'webp';
export const JPEG = '.jpeg';
export const JPG = '.jpg';
export const PNG = '.png';
export const GIF = '.gif';
export const TIFF = '.tiff';
export const BMP = '.bmp';

export const IMAGE_EXTENSIONS = [JPEG, JPG, PNG, GIF, TIFF, BMP];

export const VIDEO_EXTENSIONS = [
  '.mp4', // MPEG-4 Video
  '.mkv', // Matroska Video
  '.mov', // QuickTime Movie
  '.avi', // Audio Video Interleave
  '.flv', // Flash Video
  '.wmv', // Windows Media Video
  '.webm', // WebM Video
  '.mpeg', // MPEG Video
  '.mpg', // MPEG Video
  '.m4v', // MPEG-4 Video
  '.f4v', // Flash MP4 Video
];

export const MASTER_PLAYLIST = '.m3u8'; // Master playlist

export const zIndex = {
  popperElement: 100,
  popperReferenceElement: 101,
  navigationBar: 102,
  menuBar: 102,
  modal: 103,
  sideBarMenuOverlay: 104,
  sideBarMenu: 105,
  expanded: 106,
  tooltip: 107,
  notification: 108,
};

import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

export const EVENTS = {
  API: {
    UNAUTHORIZED_ACCESS_REQUEST: 'api/unauthorizedAccessRequest',
    BAD_REQUEST: 'api/badRequest',
  },
  WS: {
    CHAT_MESSAGE_RECEIVED: 'ws/chatMessageReceived',
    NOTIFICATION_RECEIVED: 'ws/notificationReceived',
    GET_ALL_CHANNEL_MESSAGES: 'chat:message_history',
    PARTICIPANT_LAST_SEEN_UPDATED: 'chat:participant_last_seen_updated',
    MESSAGE_DELETED: 'chat:message_deleted',
  },
};

export default eventEmitter;

import { Currency, ExperienceType } from 'helpers/constants/enums';
import { ExperienceStatus } from 'uiModels/enums';
import AbstractRequest from './AbstractRequest';
import { ApiMethod } from './constants';
import {
  CityResponse,
  ContentCreator,
  DomainResponse,
  LanguageResponse,
} from './types/experience';
import { MyExperience, PackageJoinStatus } from './types/package';
import { FileRequestResponse } from './types/file';
import { Consumer } from './types/user';
import { GpsCoordinates } from './MapOsmRequest';
import { StripeSessionLink } from './types/stripe';

export interface ApiRequestBodySetPackage {
  title: string;
  type: ExperienceType;
  domainId: string;
  languageId: string;
  cityId: string;
  startTime: Date | null;
  endTime: Date | null;
  maxConsumersCount: number;
  presentationVideoId: string;
  presentationPhotoId: string;
  description: string;
  creatorDisclaimer: string;
  price: number;
  currency: Currency;
  additionalCosts: string | null;
  discountPercent: number | null;
  purchaseDeadline: Date | null;
  isPermanent: boolean;
  gpsCoordinates: GpsCoordinates;
}

export interface MyPackagesResponse {
  results: MyExperience[];
  total: number;
}

export interface ApiRequestBodySetBenefitsRequirements {
  name: string;
  description: string;
  iconId: string;
}

export interface ApiResponseCheckCoupon {
  id: string;
  percentOff: number;
}

export interface ApiResponseGetPackages {
  results: ApiResponsePackage[];
  total: number;
}

export interface ApiResponsePackage {
  id: string;
  title: string;
  type: string;
  domain: DomainResponse;
  language: LanguageResponse;
  city: CityResponse;
  startTime: Date;
  endTime: Date;
  createdAt: string;
  maxConsumersCount: number;
  presentationVideo: FileRequestResponse;
  presentationPhoto: FileRequestResponse;
  description: string;
  creatorDisclaimer: string;
  price: number;
  currency: string;
  additionalCosts: string;
  discountPercent: number;
  purchaseDeadline: Date;
  benefits: [];
  requirements: [];
  gpsCoordinates: GpsCoordinates;
  repeats?: string;
  isPublic?: boolean;
  consumers: Consumer[];
  isCanceled?: boolean;
  isPermanent: boolean;
  isShadowBanned?: boolean;
  permanentPackageParentId: string | null;
  status?: ExperienceStatus;
  contentCreator: ContentCreator;
  pinCodeValidatedAt?: string;
  packageChatChannel: { chatChannelId: string; packageId: string } | null;
  slug: { contentCreatorFullName: string; packageTitle: string };
}

export interface ApiResponseGetExperienceRequests {
  results: RequestResult[];
  total: number;
}

export interface ApiResponseHasUpcomingInstanceOfPermanentPackage {
  isUpcomingInstanceOfPermanent: boolean;
  ippId?: string;
}

export interface RequestResult {
  id: string;
  status: string;
  package: MyExperience;
  createdAt: string;
  acceptedCreatedAt: string | null;
}

export class PackageRequest extends AbstractRequest {
  get apiResource(): string {
    return 'package';
  }

  get dynamicIds(): Record<string, string> {
    return {
      id: '{id}',
      contentCreatorSlug: '{contentCreatorSlug}',
      experienceSlug: '{experienceSlug}',
      code: '{code}',
    };
  }

  get routes(): Record<string, string> {
    return {
      SET_PACKAGE: `${this.apiRoute}`,
      SET_BENEFITS: `${this.apiRoute}/${this.dynamicIds.id}/sync-benefits`,
      SET_REQUIREMENTS: `${this.apiRoute}/${this.dynamicIds.id}/sync-requirements`,
      GET_PACKAGES: `${this.apiRoute}`,
      GET_MY_PACKAGES: `${this.apiRoute}/auth-content-creator`,
      GET_MY_PACKAGES_CONSUMER: `${this.apiRoute}/auth-consumer`,
      GET_PACKAGE: `${this.apiRoute}/${this.dynamicIds.id}`,
      GET_PACKAGE_BY_SLUG: `${this.apiRoute}/${this.dynamicIds.contentCreatorSlug}/${this.dynamicIds.experienceSlug}`,
      GET_PACKAGES_BY_CONTENT_CREATOR_ACTIVE: `${this.apiRoute}/by-content-creator-id/${this.dynamicIds.id}/active`,
      GET_PACKAGES_BY_CONTENT_CREATOR_COMPLETED: `${this.apiRoute}/by-content-creator-id/${this.dynamicIds.id}/completed`,
      CHECK_COUPON: `${this.apiRoute}-stripe/check-coupon/${this.dynamicIds.code}`,
      CREATE_STRIPE_SESSION: `${this.apiRoute}-stripe/create-session/${this.dynamicIds.id}`,
      PUBLISH_PACKAGE: `${this.apiRoute}-stripe/publish/${this.dynamicIds.id}`,
      UNPUBLISH_PACKAGE: `${this.apiRoute}-stripe/unpublish/${this.dynamicIds.id}`,
      REQUEST_REFUND: `${this.apiRoute}-stripe/${this.dynamicIds.id}/request-refund`,
      DELETE_PACKAGE: `${this.apiRoute}/${this.dynamicIds.id}`,
      DUPLICATE_PACKAGE: `${this.apiRoute}/${this.dynamicIds.id}/duplicate`,
      UPDATE_PACKAGE: `${this.apiRoute}/${this.dynamicIds.id}`,
      CANCEL_PACKAGE: `${this.apiRoute}/${this.dynamicIds.id}/cancel`,
      SET_FAVORITE: `${this.apiRoute}/${this.dynamicIds.id}/favorite`,
      SET_UNFAVORITE: `${this.apiRoute}/${this.dynamicIds.id}/unfavorite`,
      GET_FAVORITES: `${this.apiRoute}/favorites`,
      CHECK_IF_FAVORITE: `${this.apiRoute}/${this.dynamicIds.id}/check-if-favorited`,
      UPDATE_PURCHASED_PACKAGE_DATE: `${this.apiRoute}/${this.dynamicIds.id}/update-purchased-package-dates`,
      ACCEPT_DATE_UPDATE: `${this.apiRoute}/decision/${this.dynamicIds.id}/accept-date-update`,
      REJECT_DATE_UPDATE: `${this.apiRoute}/decision/${this.dynamicIds.id}/reject-date-update`,
      REQUEST_TO_JOIN: `${this.apiRoute}/${this.dynamicIds.id}/request-to-join`,
      ACCEPT_REQUEST: `${this.apiRoute}/request-to-join/${this.dynamicIds.id}/accept`,
      REJECT_REQUEST: `${this.apiRoute}/request-to-join/${this.dynamicIds.id}/reject`,
      BUYABLE_PACKAGE: `${this.apiRoute}/${this.dynamicIds.id}/permanent/is-buyable`,
      REQUESTABLE_PACKAGE: `${this.apiRoute}/${this.dynamicIds.id}/permanent/is-requestable`,
      HAS_UPCOMING_INSTANCE_OF_PERMANENT_PACKAGE: `${this.apiRoute}/${this.dynamicIds.id}/permanent/has-upcoming-ipp`,
      CHECK_REQUEST_STATUS: `${this.apiRoute}/request-to-join/${this.dynamicIds.id}`,
      REQUEST_PIN: `${this.apiRoute}/${this.dynamicIds.id}/show-pin-code`,
      VALIDATE_PIN: `${this.apiRoute}/${this.dynamicIds.id}/validate-pin-code`,
      INVITE_GUEST: `${this.apiRoute}/${this.dynamicIds.id}/invite-guest`,
      REQUESTS_TO_JOIN: `${this.apiRoute}/request-to-join`,
    };
  }

  async getPackage(id: string): Promise<ApiResponsePackage> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_PACKAGE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async getPackageBySlug(
    contentCreatorSlug: string,
    experienceSlug: string
  ): Promise<ApiResponsePackage> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes
        .GET_PACKAGE_BY_SLUG!.replace(
          this.dynamicIds.contentCreatorSlug!,
          contentCreatorSlug
        )
        .replace(this.dynamicIds.experienceSlug!, experienceSlug)
    );

    return result.data;
  }

  async checkCoupon(couponCode: string): Promise<ApiResponseCheckCoupon> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.CHECK_COUPON!.replace(this.dynamicIds.code!, couponCode)
    );

    return result.data;
  }

  async deletePackage(id: string) {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE_PACKAGE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async duplicatePackage(id: string) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.DUPLICATE_PACKAGE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async updatePackage(
    id: string,
    data: ApiRequestBodySetPackage
  ): Promise<string> {
    const result = await this.request(
      ApiMethod.PATCH,
      this.routes.UPDATE_PACKAGE!.replace(this.dynamicIds.id!, id),
      data
    );

    return result.data;
  }

  async cancelPackage(id: string) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.CANCEL_PACKAGE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async getActivePackagesByContentCreatorId(
    id: string,
    limit: number,
    offset: number
  ): Promise<ApiResponseGetPackages> {
    const queryParams = [
      { key: 'limit', value: `${limit}` },
      { key: 'offset', value: `${offset}` },
    ];
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_PACKAGES_BY_CONTENT_CREATOR_ACTIVE!.replace(
        this.dynamicIds.id!,
        id
      ) + this.buildQueryParams(queryParams)
    );

    return result.data;
  }

  async getCompletedPackagesByContentCreatorId(
    id: string,
    limit: number,
    offset: number
  ): Promise<ApiResponseGetPackages> {
    const queryParams = [
      { key: 'limit', value: `${limit}` },
      { key: 'offset', value: `${offset}` },
    ];
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_PACKAGES_BY_CONTENT_CREATOR_COMPLETED!.replace(
        this.dynamicIds.id!,
        id
      ) + this.buildQueryParams(queryParams)
    );

    return result.data;
  }

  async setPackage(
    data: ApiRequestBodySetPackage
  ): Promise<ApiResponsePackage> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_PACKAGE!,
      data
    );

    return result.data;
  }

  async getMyPackages(
    limit: number,
    offset: number,
    type?: string,
    status?: string
  ): Promise<MyPackagesResponse> {
    const queryParams = [
      { key: 'limit', value: `${limit}` },
      { key: 'offset', value: `${offset}` },
    ];
    if (type) {
      queryParams.push({ key: 'type', value: type });
    }
    if (status) {
      queryParams.push({ key: 'status', value: status });
    }
    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_MY_PACKAGES + this.buildQueryParams(queryParams)
    );
    return result.data;
  }

  async getMyPackagesConsumer(
    limit: number,
    offset: number,
    status: string
  ): Promise<MyPackagesResponse> {
    const queryParams = [
      { key: 'limit', value: `${limit}` },
      { key: 'offset', value: `${offset}` },
    ];

    queryParams.push({ key: 'status', value: status });

    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_MY_PACKAGES_CONSUMER + this.buildQueryParams(queryParams)
    );
    return result.data;
  }

  async setBenefits(
    data: { benefits: ApiRequestBodySetBenefitsRequirements[] },
    id: string
  ): Promise<ApiRequestBodySetPackage> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_BENEFITS!.replace(this.dynamicIds.id!, id),
      data
    );

    return result.data;
  }

  async setRequirements(
    data: { requirements: ApiRequestBodySetBenefitsRequirements[] },
    id: string
  ): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_REQUIREMENTS!.replace(this.dynamicIds.id!, id),
      data
    );

    return result.data;
  }

  async getPackages(queryOptions: {
    limit: number;
    offset: number;
    domain?: string;
    type?: string;
    minPrice?: number;
    maxPrice?: number;
    currency?: string;
    countryId?: string;
    cityId?: string;
    languageId?: string;
    searchText?: string;
  }): Promise<ApiResponseGetPackages> {
    const queryParams = [
      { key: 'limit', value: `${queryOptions.limit}` },
      { key: 'offset', value: `${queryOptions.offset}` },
    ];
    if (queryOptions.domain) {
      queryParams.push({ key: 'domain', value: queryOptions.domain });
    }
    if (queryOptions.type) {
      queryParams.push({ key: 'type', value: queryOptions.type });
    }
    if (queryOptions.minPrice) {
      queryParams.push({ key: 'minPrice', value: `${queryOptions.minPrice}` });
    }
    if (queryOptions.maxPrice) {
      queryParams.push({ key: 'maxPrice', value: `${queryOptions.maxPrice}` });
    }
    if (queryOptions.currency) {
      queryParams.push({ key: 'currency', value: queryOptions.currency });
    }
    if (queryOptions.countryId) {
      queryParams.push({ key: 'countryId', value: queryOptions.countryId });
    }
    if (queryOptions.cityId) {
      queryParams.push({ key: 'cityId', value: queryOptions.cityId });
    }
    if (queryOptions.languageId) {
      queryParams.push({ key: 'languageId', value: queryOptions.languageId });
    }
    if (queryOptions.searchText) {
      queryParams.push({ key: 'searchText', value: queryOptions.searchText });
    }

    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_PACKAGES + this.buildQueryParams(queryParams)
    );
    return result.data;
  }

  async createStripeSession(
    id: string,
    couponCodeId?: string
  ): Promise<StripeSessionLink> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.CREATE_STRIPE_SESSION!.replace(this.dynamicIds.id!, id),
      { couponCodeId }
    );

    return result.data;
  }

  async publishPackage(id: string) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.PUBLISH_PACKAGE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async unpublishPackage(id: string) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.UNPUBLISH_PACKAGE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async requestRefundPackage(id: string) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.REQUEST_REFUND!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async favoritePackage(id: string) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_FAVORITE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async unfavoritePackage(id: string) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SET_UNFAVORITE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async getFavoritesPackages(queryOptions: {
    limit: number;
    offset: number;
  }): Promise<ApiResponseGetPackages> {
    const queryParams = [
      { key: 'limit', value: `${queryOptions.limit}` },
      { key: 'offset', value: `${queryOptions.offset}` },
    ];

    const result = await this.request(
      ApiMethod.GET,
      this.routes.GET_FAVORITES + this.buildQueryParams(queryParams)
    );
    return result.data;
  }

  async checkIfPackageFavorited(id: string): Promise<boolean> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.CHECK_IF_FAVORITE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async checkExperienceRequestStatus(id: string): Promise<PackageJoinStatus> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.CHECK_REQUEST_STATUS!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async updatePurchasedPackageDates(
    id: string,
    data: {
      newStartTime: Date;
      newEndTime: Date;
    }
  ): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.UPDATE_PURCHASED_PACKAGE_DATE!.replace(
        this.dynamicIds.id!,
        id
      ),
      data
    );

    return result.data;
  }

  async acceptDateUpdate(id: string): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.ACCEPT_DATE_UPDATE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async rejectDateUpdate(id: string): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.REJECT_DATE_UPDATE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async requestToJoin(id: string): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.REQUEST_TO_JOIN!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async acceptRequest(id: string): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.ACCEPT_REQUEST!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async rejectRequest(id: string): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.REJECT_REQUEST!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async isBuyablePackage(id: string): Promise<boolean> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.BUYABLE_PACKAGE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async isRequestablePackage(id: string): Promise<boolean> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.REQUESTABLE_PACKAGE!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async hasUpcomingInstanceOfPermanentPackage(
    id: string
  ): Promise<ApiResponseHasUpcomingInstanceOfPermanentPackage> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.HAS_UPCOMING_INSTANCE_OF_PERMANENT_PACKAGE!.replace(
        this.dynamicIds.id!,
        id
      )
    );

    return result.data;
  }

  async showPin(id: string): Promise<number> {
    const result = await this.request(
      ApiMethod.GET,
      this.routes.REQUEST_PIN!.replace(this.dynamicIds.id!, id)
    );

    return result.data;
  }

  async validatePin(
    id: string,
    data: {
      pinCode: number;
    }
  ): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.VALIDATE_PIN!.replace(this.dynamicIds.id!, id),
      data
    );

    return result.data;
  }

  async inviteGuest(
    id: string,
    data: {
      email: string;
    }
  ): Promise<string> {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.INVITE_GUEST!.replace(this.dynamicIds.id!, id),
      data
    );

    return result.data;
  }

  async getRequestsToJoin(
    limit: number,
    offset: number
  ): Promise<ApiResponseGetExperienceRequests> {
    const queryParams = [
      { key: 'limit', value: `${limit}` },
      { key: 'offset', value: `${offset}` },
    ];
    const result = await this.request(
      ApiMethod.GET,
      this.routes.REQUESTS_TO_JOIN! + this.buildQueryParams(queryParams)
    );

    return result.data;
  }
}

export const packageRequest = new PackageRequest();

import LoadingSpinner from 'components/dumb/atomics/LoadingSpinner';
import { lazy, Suspense } from 'react';
import * as SC from './styles';

const ExplorerIcon = lazy(
  () => import('assets/icons/introductionIcons/ExplorerIcon')
);

function LazyExplorerIcon() {
  return (
    <Suspense
      fallback={
        <SC.CenteredContainer>
          <LoadingSpinner />
        </SC.CenteredContainer>
      }
    >
      <ExplorerIcon />
    </Suspense>
  );
}

export default LazyExplorerIcon;

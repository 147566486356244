import i18n from 'i18next';

export const loadLocaleData = (language: string) => {
  switch (language) {
    case 'en':
      return import(/* webpackChunkName: "locale-en" */ './en');
    case 'ro':
      return import(/* webpackChunkName: "locale-ro" */ './ro');
    default:
      return import(/* webpackChunkName: "locale-en" */ './en');
  }
};

export const handleLanguageChange = async (newLanguage: string) => {
  try {
    const languageWithoutRegionCode = newLanguage.split('-')[0] || '';

    const translations = await loadLocaleData(languageWithoutRegionCode);

    i18n.addResourceBundle(
      languageWithoutRegionCode,
      'translations',
      translations.default.translations,
      true, // Force overwrite in case resources already exist
      true // Merge translations instead of replacing the entire namespace
    );

    i18n.changeLanguage(languageWithoutRegionCode);
  } catch (error) {
    console.error('Error loading translations:', error);
  }
};

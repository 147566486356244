const NATIVE_ACTIONS = {
  LOGIN: 'NativeLogin',
  LOGOUT: 'NativeLogout',
  SHARE: 'NativeShare',
  DOWNLOAD_FILE_UNAUTHENTICATED: 'DownloadFileUnauthenticated',
  OPEN_BROWSER_POPUP: 'OpenBrowserPopup',
  GOOGLE_LOGIN: 'NativeGoogleLogin',
  ROUTE_TO_MAP: 'NativeRouteToMap',
  CHECK_TEXT_AREA: 'NativeCheckTextArea',
};

export default NATIVE_ACTIONS;

import ErrorBoundaryCustom from 'components/dumb/layouts/ErrorBoundaryCustom';
import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PATH from 'routes/paths';
import { useTranslation } from 'react-i18next';
import { EVENTS } from 'eventEmitter';
import useEventListen from 'hooks/useEventListen';
import useInAppNavigate from '../../../hooks/useInAppNavigate';
import { useNetworkStatus } from '../../../hooks/network/useNetworkStatus';

interface ErrorBoundarySmartProps {
  children?: ReactNode;
}

export default function ErrorBoundarySmart({
  children,
}: ErrorBoundarySmartProps) {
  const { t } = useTranslation();
  const { navigate } = useInAppNavigate();
  const isOnline = useNetworkStatus();
  const { isEventTriggered: isBadRequest, handleResetEvent } = useEventListen(
    EVENTS.API.BAD_REQUEST
  );

  const reloadWindow = () => {
    window.location.reload();
  };

  if (!isOnline) {
    return (
      <ErrorBoundaryCustom
        title={t('networkError.title')}
        subtitle={t('networkError.subtitle')}
        action={t('networkError.action')}
        onResetErrorBoundary={reloadWindow}
      />
    );
  }

  if (isBadRequest) {
    return (
      <ErrorBoundaryCustom
        onResetErrorBoundary={() => {
          handleResetEvent();
          navigate(PATH.INDEX);
        }}
      />
    );
  }

  return (
    <ErrorBoundary
      fallback={
        <ErrorBoundaryCustom
          onResetErrorBoundary={() => navigate(PATH.INDEX)}
        />
      }
    >
      {children}
    </ErrorBoundary>
  );
}

import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedOnboardingIndexPage = lazy(
  () =>
    import('../components/smart/pages/onboarding/ConnectedOnboardingIndexPage')
);
const ConnectedOnboardingExplorePage = lazy(
  () =>
    import(
      '../components/smart/pages/onboarding/ConnectedOnboardingExplorePage'
    )
);
const ConnectedOnboardingChatPage = lazy(
  () =>
    import('../components/smart/pages/onboarding/ConnectedOnboardingChatPage')
);
const ConnectedOnboardingChooseYourRolePage = lazy(
  () =>
    import(
      '../components/smart/pages/onboarding/ConnectedOnboardingChooseYourRolePage'
    )
);
const ConnectedOnboardingBuildProfilePage = lazy(
  () =>
    import(
      '../components/smart/pages/onboarding/ConnectedOnboardingBuildProfilePage'
    )
);
const ConnectedOnboardingChooseDomainsPage = lazy(
  () =>
    import(
      '../components/smart/pages/onboarding/ConnectedOnboardingChooseDomainsPage'
    )
);
const ConnectedOnboardingChooseHobbiesPage = lazy(
  () =>
    import(
      '../components/smart/pages/onboarding/ConnectedOnboardingChooseHobbiesPage'
    )
);
const ConnectedOnboardingChooseProfilePicturePage = lazy(
  () =>
    import(
      '../components/smart/pages/onboarding/ConnectedOnboardingChooseProfilePicturePage'
    )
);
const ConnectedOnboardingProfileIsSetPage = lazy(
  () =>
    import(
      '../components/smart/pages/onboarding/ConnectedOnboardingProfileIsSetPage'
    )
);

const ONBOARDING_INDEX: Route = {
  component: ConnectedOnboardingIndexPage,
  path: PATH.ONBOARDING.INDEX,
  isProtected: false,
  redirectIfAuthenticated: true,
  isOnboarding: true,
};

const ONBOARDING_EXPLORE: Route = {
  component: ConnectedOnboardingExplorePage,
  path: PATH.ONBOARDING.EXPLORE,
  isProtected: false,
  redirectIfAuthenticated: true,
  isOnboarding: true,
};

const ONBOARDING_CHAT: Route = {
  component: ConnectedOnboardingChatPage,
  path: PATH.ONBOARDING.CHAT,
  isProtected: true,
  isOnboarding: true,
};

const ONBOARDING_CHOOSE_ROLE: Route = {
  component: ConnectedOnboardingChooseYourRolePage,
  path: PATH.ONBOARDING.CHOOSE_ROLE,
  isProtected: true,
  isOnboarding: true,
};

const ONBOARDING_BUILD_PROFILE: Route = {
  component: ConnectedOnboardingBuildProfilePage,
  path: PATH.ONBOARDING.BUILD_PROFILE,
  isProtected: true,
  isOnboarding: true,
};

const ONBOARDING_CHOOSE_DOMAINS: Route = {
  component: ConnectedOnboardingChooseDomainsPage,
  path: PATH.ONBOARDING.CHOOSE_DOMAINS,
  isProtected: true,
  isOnboarding: true,
};

const ONBOARDING_CHOOSE_HOBBIES: Route = {
  component: ConnectedOnboardingChooseHobbiesPage,
  path: PATH.ONBOARDING.CHOOSE_HOBBIES,
  isProtected: true,
  isOnboarding: true,
};

const ONBOARDING_CHOOSE_PROFILE_PICTURE: Route = {
  component: ConnectedOnboardingChooseProfilePicturePage,
  path: PATH.ONBOARDING.CHOOSE_PROFILE_PICTURE,
  isProtected: true,
  isOnboarding: true,
};

const ONBOARDING_PROFILE_IS_SET: Route = {
  component: ConnectedOnboardingProfileIsSetPage,
  path: PATH.ONBOARDING.PROFILE_IS_SET,
  isProtected: true,
  isOnboarding: true,
};

export default [
  ONBOARDING_INDEX,
  ONBOARDING_EXPLORE,
  ONBOARDING_CHAT,
  ONBOARDING_CHOOSE_ROLE,
  ONBOARDING_BUILD_PROFILE,
  ONBOARDING_CHOOSE_DOMAINS,
  ONBOARDING_CHOOSE_HOBBIES,
  ONBOARDING_CHOOSE_PROFILE_PICTURE,
  ONBOARDING_PROFILE_IS_SET,
];

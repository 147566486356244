import { ButtonHTMLAttributes, ReactNode } from 'react';
import { useTheme } from 'styled-components';
import * as SC from './styles';
import classic from '../../../../styles/theme/classic';
import LoadingSpinner from '../LoadingSpinner';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  textColor?: string;
  color: string;
  disabled?: boolean;
  href?: string;
  children: ReactNode;
  [rest: string]: unknown;
}

export default function Button({
  isLoading = false,
  textColor = classic.palette.main.white,
  color,
  disabled = false,
  href,
  children,
  ...rest
}: ButtonProps) {
  const theme = useTheme();
  const isDisabled: boolean = disabled || isLoading;
  const hasLink = href && href.length > 0;

  const getHoverColor = () => {
    if (color === theme.palette.button.main.yellow) {
      return theme.palette.button.hover.yellow;
    }
    if (color === theme.palette.button.main.marine) {
      return theme.palette.button.hover.marine;
    }
    if (color === theme.palette.button.main.turquoise) {
      return theme.palette.button.hover.turquoise;
    }
    if (color === theme.palette.button.main.yellowTurquoise) {
      return theme.palette.button.hover.yellowTurquoise;
    }
    if (color === theme.palette.button.main.turquoiseYellow) {
      return theme.palette.button.hover.turquoiseYellow;
    }
    if (color === theme.palette.main.blueGreen) {
      return `${theme.palette.main.blueGreen}B3`;
    }
    if (color === theme.palette.main.lightGray) {
      return `${theme.palette.main.lightGray}B3`;
    }
    return undefined;
  };

  return (
    <SC.Container
      color={color}
      $textColor={textColor}
      $hoverColor={getHoverColor()}
      disabled={isDisabled}
      {...rest}
    >
      {hasLink && <SC.LinkOverlay to={href} />}
      {isLoading && <LoadingSpinner />}
      {children}
    </SC.Container>
  );
}

import { useEffect, useState } from 'react';

const SWIPE_THRESHOLD = 30;

const useCloseSidebarOnSwipe = (
  isSidebarOpen: boolean,
  onCloseSidebar: CallbackFunction
) => {
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const handleSwipe = () => {
    const swipeDistance = touchEndX - touchStartX;
    if (
      isSidebarOpen &&
      touchStartX < SWIPE_THRESHOLD &&
      swipeDistance > SWIPE_THRESHOLD
    ) {
      onCloseSidebar();
    }
  };

  const handleTouchMove = (event: TouchEvent) => {
    const touch = event.touches?.[0];
    if (isSidebarOpen && touchStartX < SWIPE_THRESHOLD && touch) {
      event.preventDefault();
    }
  };

  const handleTouchStart = (event: TouchEvent) => {
    const firstTouch = event.touches?.[0];
    if (firstTouch) {
      setTouchStartX(firstTouch.clientX);
    }
  };

  const handleTouchEnd = (event: TouchEvent) => {
    const lastTouch = event.changedTouches?.[0];
    if (lastTouch) {
      setTouchEndX(lastTouch.clientX);
      handleSwipe();
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
      document.addEventListener('touchstart', handleTouchStart, {
        passive: false,
      });
      document.addEventListener('touchend', handleTouchEnd, { passive: false });
    }

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isSidebarOpen, touchStartX, touchEndX]);
};

export default useCloseSidebarOnSwipe;

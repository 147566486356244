import { useEffect } from 'react';
import * as SC from './styles';
import VideoPlayer from '../VideoPlayer';
import usePreloadMindFeed from '../../../../hooks/mindFeed/usePreloadMindFeed';

export default function VideoPreloader() {
  const { videos } = usePreloadMindFeed();

  useEffect(() => {}, [videos]);

  if (videos.length === 0) {
    return null;
  }

  return (
    <SC.Container>
      {videos.map((video) => (
        <VideoPlayer
          key={video.id}
          video={video.source}
          width="0px"
          height="0px"
        />
      ))}
    </SC.Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 262px;
  margin: 0 auto;
`;

export const DateContainer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 175, 185, 0.7) 0%,
    rgba(0, 129, 170, 0.7) 100%
  );
  padding: 6px;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const DateText = styled.p`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(2)};
  color: ${({ theme }) => theme.palette.main.white};
`;

export const ContestContainer = styled.div`
  background: ${({ theme }) => `${theme.palette.main.blueGreen}B3`};
  padding: 6px;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const ContestText = styled.div`
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typography.pxToRem(-2)};
  line-height: ${({ theme }) => theme.typography.pxToRem(2)};
  color: ${({ theme }) => theme.palette.main.white};
`;

export const IconContainer = styled.div`
  svg {
    path {
      fill: ${({ theme }) => theme.palette.main.white};
    }

    width: 24px;
    height: 24px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

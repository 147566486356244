import { Placement } from 'uiModels/enums/floatingUI';
import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import Tooltip from '../Tooltip';
import { VerifiedBadgeIcon } from '../../../../assets/icons/VerifiedBadgeIcon';

interface Props {
  placement?: Placement;
}

export default function VerifiedBadge({ placement = Placement.TOP }: Props) {
  const { t } = useTranslation();

  return (
    <Tooltip content={t('tooltip.verifiedBadge')} placement={placement}>
      <SC.VerifiedBadgeIconContainer>
        <VerifiedBadgeIcon />
      </SC.VerifiedBadgeIconContainer>
    </Tooltip>
  );
}

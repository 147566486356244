import { ReactNode } from 'react';
import { t } from 'i18next';
import { ExperienceType, SvgPath } from '../../constants/enums';
import ShadowingIcon from '../../../assets/icons/experience/details/ShadowingIcon';
import HandsOnIcon from '../../../assets/icons/experience/details/HandsOnIcon';
import MentorshipIcon from '../../../assets/icons/experience/details/MentorshipIcon';

export interface ExperienceTypeOption {
  value: ExperienceType;
  icon: ReactNode;
  path: SvgPath;
  label: string;
  description: string;
}

export const buildExperienceTypesRecord = (): Record<
  ExperienceType,
  ExperienceTypeOption
> => ({
  [ExperienceType.SHADOWING]: {
    value: ExperienceType.SHADOWING,
    icon: <ShadowingIcon />,
    path: SvgPath.FILL,
    label: t(`experienceCreationLayout.step18.shadowing`),
    description: t(`experienceCreationLayout.step18.shadowingDescription`),
  },
  [ExperienceType.HANDS_ON]: {
    value: ExperienceType.HANDS_ON,
    icon: <HandsOnIcon />,
    path: SvgPath.STROKE,
    label: t(`experienceCreationLayout.step18.handsOn`),
    description: t(`experienceCreationLayout.step18.handsOnDescription`),
  },
  [ExperienceType.MENTORSHIP]: {
    value: ExperienceType.MENTORSHIP,
    icon: <MentorshipIcon />,
    path: SvgPath.FILL,
    label: t(`experienceCreationLayout.step18.mentorship`),
    description: t(`experienceCreationLayout.step18.mentorshipDescription`),
  },
});

export const buildExperienceTypesArray = (): ExperienceTypeOption[] =>
  Object.values(buildExperienceTypesRecord());

export const getExperienceTypeOptionByValue = (
  experienceValue: ExperienceType
): ExperienceTypeOption => buildExperienceTypesRecord()[experienceValue];

export const renderExperienceTypeLabel = (type: string) => {
  switch (type) {
    case ExperienceType.HANDS_ON:
      return t(`experience.type.handsOn`);
    case ExperienceType.MENTORSHIP:
      return t(`experience.type.mentorship`);
    case ExperienceType.SHADOWING:
      return t(`experience.type.shadowing`);
    default:
      return null;
  }
};
